import { IconProps } from '@chakra-ui/react'
import React from 'react'

const PlayVideoIcon: React.FC<IconProps> = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="50.000000pt"
      height="50.000000pt"
      viewBox="0 0 50.000000 50.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
        fill="#ffffff"
        stroke="none"
      >
        <path
          d="M100 250 c0 -104 3 -190 7 -190 10 0 323 184 323 190 0 6 -313 190
-323 190 -4 0 -7 -85 -7 -190z"
        />
      </g>
    </svg>
  )
}

export default PlayVideoIcon
