import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Mousewheel, Navigation, SwiperOptions } from 'swiper'
import ReelItem from './reel-item'
import { useReel } from '@lib/context/reel-context'
import { useRouter } from 'next/router'

interface ListReelsProps {
  breakpoints?: {
    [width: number]: SwiperOptions
    [ratio: string]: SwiperOptions
  }
  isModal?: boolean
}

const ListReels: React.FC<ListReelsProps> = ({ breakpoints, isModal }) => {
  const { reels, isOpen, slideIndex, setSlideIndex, onOpen, auto, setAuto } =
    useReel()
  const [swiperRef, setSwiperRef] = useState<any>(null)

  const router = useRouter()

  const videoId = router?.query['videoId']

  const slideTo = (index: number) => {
    swiperRef.slideTo(index, 0)
  }

  useEffect(() => {
    if (swiperRef != null && isOpen && auto) {
      slideTo(slideIndex)
      setAuto(false)
    }
  }, [swiperRef, slideIndex, isOpen, auto])

  useEffect(() => {
    if (videoId != null) {
      const reelIndex = reels.findIndex((r) => r.id === (videoId as string))
      if (reelIndex !== -1) {
        onOpen()
        setSlideIndex(reelIndex)
      }
    }
  }, [videoId])

  const defaultBreakPoints = {
    320: {
      slidesPerView: 2,
      spaceBetween: 20
    },
    480: {
      slidesPerView: 3,
      spaceBetween: 20
    },
    640: {
      slidesPerView: 3,
      spaceBetween: 20
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 40
    },
    1024: {
      slidesPerView: 4,
      spaceBetween: 50
    }
  }

  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={10}
      onSwiper={(swiper) => {
        setSwiperRef(swiper)
      }}
      pagination={{
        clickable: isOpen
      }}
      navigation={{
        enabled: true
      }}
      breakpoints={breakpoints ?? defaultBreakPoints}
      modules={[Navigation, Mousewheel]}
      loop={true}
      className="listReel h-full"
      onActiveIndexChange={(swiper) => {
        if (slideIndex !== swiper.realIndex) {
          setSlideIndex(swiper.realIndex)
        }
      }}
    >
      {reels.length > 0 &&
        reels.map((reel, index) => {
          return (
            <SwiperSlide key={reel.id}>
              <ReelItem
                key={reel.id}
                reel={reel}
                index={index}
                isModal={isModal}
              />
            </SwiperSlide>
          )
        })}
    </Swiper>
  )
}

export default ListReels
