import { IconProps } from '@chakra-ui/react'
import React from 'react'

const ShareIcon: React.FC<IconProps> = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="24.000000pt"
      height="24.000000pt"
      viewBox="0 0 24.000000 24.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
        fill="white"
        stroke="none"
      >
        <path
          d="M130 205 c0 -17 -6 -25 -17 -25 -56 0 -113 -73 -113 -142 l1 -33 20
37 c32 61 109 91 109 43 0 -26 1 -25 54 17 l54 43 -46 42 c-26 23 -50 42 -54
43 -5 0 -8 -11 -8 -25z"
        />
      </g>
    </svg>
  )
}

export default ShareIcon
