import { IconProps } from '@chakra-ui/react'
import React from 'react'

const MuteIcon: React.FC<IconProps> = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="14.000000pt"
      height="14.000000pt"
      viewBox="0 0 14.000000 14.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,14.000000) scale(0.08000,-0.08000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M12 144 c11 -12 14 -27 11 -55 -5 -33 -3 -39 12 -39 10 0 33 -11 51
-25 21 -16 35 -21 39 -15 5 8 12 7 23 -1 10 -7 -19 24 -63 69 -44 45 -82 82
-84 82 -3 0 2 -7 11 -16z"
        />
        <path d="M91 139 l-24 -20 23 -24 c32 -34 40 -31 40 14 0 49 -8 55 -39 30z" />
      </g>
    </svg>
  )
}

export default MuteIcon
