import React from 'react'
import { IconPropsType } from './icon-props'

const HeartIcon: React.FC<IconPropsType> = ({ color }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="24.000000pt"
      height="24.000000pt"
      viewBox="0 0 24.000000 24.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
        stroke="none"
        fill={color ?? 'white'}
      >
        <path
          d="M23 198 c-20 -26 -22 -50 -8 -78 16 -30 85 -90 105 -90 20 0 90 60
106 91 22 43 1 83 -44 87 -18 2 -40 -2 -48 -9 -11 -9 -18 -9 -26 -1 -16 16
-73 15 -85 0z"
        />
      </g>
    </svg>
  )
}

export default HeartIcon
