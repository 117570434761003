import React, { Fragment, useEffect, useRef, useState } from 'react'
import {
  Box,
  Fade,
  Flex,
  HStack,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import ReactPlayer from 'react-player/lazy'
import Image from 'next/image'
import { useReel } from '@lib/context/reel-context'
import { Reel } from '@common/interface/reel'
import { CloseIcon, SmallCloseIcon } from '@chakra-ui/icons'
import { useRouter } from 'next/router'
import RouterUrl from '@constants/router.constant'
import HeartIcon from '@icons/heart-icon'
import ShareIcon from '@icons/share-icon'
import { useCopyToClipboard } from 'usehooks-ts'
import AddToCartIcon from '@icons/add-to-cart-icon'

import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from 'react-share'
import CopyLinkIcon from '@icons/copy-link'
import MuteIcon from '@icons/mute-icon'
import VolumeIcon from '@icons/volume-icon'
import PlayVideoIcon from '@icons/play-video-icon'
import PauseVideoIcon from '@icons/pause-video-icon'
import { useResponsive } from '@common/hooks'
import parse from 'html-react-parser'
import { Product } from '@medusajs/medusa'

interface ReelItemProps {
  reel: Reel
  index: number
  isModal?: boolean
}

const ICON_SIZE = '40px'
const NUMBER_OF_LINES = 3
const WIDTH_CONTENT = '90%'

const ProductReelItem = ({
  product,
  reel
}: {
  product: Product
  reel: Reel
}) => {
  const { addReelLocal } = useReel()
  const router = useRouter()

  return (
    <HStack
      py={1}
      px={2}
      borderRadius={5}
      onClick={() => {
        addReelLocal(reel.id, product.id)
        router.push(`${RouterUrl.PRODUCT.ROOT}/${product.handle as string} `)
      }}
      w={'100%'}
    >
      <Image
        src={product.thumbnail ?? ''}
        width={50}
        height={30}
        alt={product.title}
      />
      <VStack>
        <Text textStyle={'ITC'} fontSize={15} w={'100%'}>
          {product.title}
        </Text>
        <HStack w={'100%'}>
          <AddToCartIcon color={'black'} />
          <Text>Shop now</Text>
        </HStack>
      </VStack>
    </HStack>
  )
}

const ReelItem: React.FC<ReelItemProps> = ({ reel, index, isModal }) => {
  const {
    onOpen,
    isOpen,
    setSlideIndex,
    slideIndex,
    reels,
    addView,
    addReelLocal,
    likes,
    addLikes,
    addShare,
    hoverIndex,
    setHoverIndex,
    muted,
    setMuted,
    setAuto,
    auto,
    onClose
  } = useReel()

  const SHARE_LINK = `${
    process.env.NEXT_PUBLIC_FRONTEND_DOMAIN as string
  }?videoId=${reel.id}`

  const [playing, setPlaying] = useState(false)

  const { thumbnail, reel_video, title, products } = reel

  const product = products ? products[0] : undefined
  const ref = useRef<ReactPlayer>(null)

  const [valueCopy, copy] = useCopyToClipboard()
  const [isHover, setIsHover] = useState(false)
  const [pipMode, setPipMode] = useState(false)

  const [likeNumber, setLikeNumber] = useState(reel.likes)
  const [shareNumber, setShareNumber] = useState(reel.shares)

  const [isShopNow, setIsShopNow] = useState(false)
  const [isShowProduct, setIsShowProduct] = useState(true)

  const { smMax } = useResponsive()

  const {
    isOpen: open,
    onOpen: openHandle,
    onClose: closeHandle
  } = useDisclosure()

  useEffect(() => {
    const player = ref.current
    if (isOpen && player && slideIndex === index) {
      setPlaying(true)
      player.seekTo(0)
      setIsShopNow(false)
      setIsShowProduct(true)
    } else if (isOpen) {
      setPlaying(false)
    }
  }, [isOpen, slideIndex, auto])

  useEffect(() => {
    if (!isOpen && hoverIndex !== null) {
      setPlaying(index === hoverIndex)
    }
  }, [hoverIndex])

  return (
    <Flex
      className="reel-item"
      flexDirection="column"
      h={isOpen ? '100%' : 'auto'}
      justifyContent="space-between"
      position={'relative'}
      style={{
        aspectRatio: '9/16',
        margin: '0 auto',
        maxWidth: '100%'
      }}
      onMouseOver={() => {
        if (isOpen) {
          setIsHover(true)
        } else {
          setHoverIndex(index)
        }
      }}
      onMouseLeave={() => {
        if (isOpen) {
          setIsHover(false)
        }
      }}
      cursor={'pointer'}
      onClick={() => {
        if (!isOpen) {
          onOpen()
          setAuto(true)
          setSlideIndex(index)
        }
      }}
      zIndex={100}
    >
      <ReactPlayer
        ref={ref}
        url={reel_video}
        width="100%"
        height="100%"
        muted={isModal === true ? muted : true}
        light={
          isOpen ? (
            false
          ) : !playing ? (
            <Fade in={!playing}>
              <Image
                width={340}
                height={795}
                src={thumbnail}
                alt={title}
                style={{
                  width: isOpen ? '100%' : '340px',
                  height: isOpen ? '795px' : 'auto'
                }}
              />
            </Fade>
          ) : (
            false
          )
        }
        playing={playing}
        onEnded={() => {
          addView(reel.id, reel.views + 1)
          if (index + 1 <= reels.length - 1) {
            setSlideIndex(index + 1)
          } else {
            setSlideIndex(0)
          }
          setAuto(true)
        }}
        pip={pipMode}
        progressInterval={10}
      ></ReactPlayer>

      <Box
        position={'absolute'}
        top={0}
        left={0}
        w={'100%'}
        h={'100%'}
        bg={'#0000000a'}
      >
        {isOpen && (
          <Box
            position={'absolute'}
            top={'50%'}
            left={'50%'}
            display={isHover ? 'flex' : 'none'}
            zIndex={1000}
          >
            <IconButton
              aria-label="on-video-reel"
              icon={playing ? <PauseVideoIcon /> : <PlayVideoIcon />}
              bg={'transparent'}
              _hover={{
                bg: 'transparent'
              }}
              onClick={() => {
                setPlaying(!playing)
              }}
              transform={'translate(-50%, -50%)'}
            />
          </Box>
        )}

        {isOpen && (
          <VStack
            position={'absolute'}
            top={10}
            right={smMax ? '1%' : '2%'}
            justifyContent={'end'}
            spacing={5}
          >
            <VStack spacing={0}>
              <IconButton
                aria-label="close button reel"
                borderRadius={'50%'}
                icon={<CloseIcon />}
                size={'sm'}
                onClick={onClose}
                zIndex={1000}
                bg={'white'}
                _hover={{
                  bg: 'white'
                }}
              />
            </VStack>
            <VStack spacing={0}>
              <IconButton
                aria-label="close button reel"
                borderRadius={'50%'}
                icon={muted ? <MuteIcon /> : <VolumeIcon />}
                size={'sm'}
                onClick={() => {
                  if (isModal === true) {
                    setMuted(!muted)
                  }
                }}
                zIndex={1000}
                bg={'white'}
                _hover={{
                  bg: 'white'
                }}
              />
            </VStack>
          </VStack>
        )}
        <Box position={'relative'} w={'100%'} h={'100%'}>
          {!isOpen && (
            <Text
              textStyle={'titleReel'}
              color={'white'}
              position={'absolute'}
              bottom={'5%'}
              left={'5%'}
            >
              {title}
            </Text>
          )}
          {isOpen && (
            <VStack
              position={'absolute'}
              top={smMax ? '58%' : '55%'}
              right={smMax ? '0%' : '1%'}
              justifyContent={'end'}
            >
              <VStack spacing={0}>
                <IconButton
                  zIndex={1000}
                  aria-label="heart-reel"
                  icon={
                    <HeartIcon
                      color={
                        likes.find((l) => l === reel.id) != null
                          ? 'red'
                          : 'white'
                      }
                    />
                  }
                  onClick={() => {
                    addLikes(reel.id, () => {
                      setLikeNumber(likeNumber + 1)
                    })
                  }}
                  bg={'transparent'}
                  _hover={{
                    bg: 'transparent'
                  }}
                />
                <Text color={'white'} mt={0} fontSize={16}>
                  {likeNumber}
                </Text>
              </VStack>
              <VStack spacing={0}>
                <IconButton
                  zIndex={1000}
                  aria-label="share-reel"
                  icon={<ShareIcon />}
                  onClick={openHandle}
                  bg={'transparent'}
                  _hover={{
                    bg: 'transparent'
                  }}
                />
                <Text color={'white'} mt={0} fontSize={16}>
                  {shareNumber}
                </Text>
              </VStack>
            </VStack>
          )}
          {isOpen && !isShopNow && !open && (
            <VStack
              position={'absolute'}
              bottom={'3%'}
              left={'0%'}
              w={'100%'}
              mx={'auto'}
              justifyContent={'center'}
              maxH={'485px'}
            >
              {isOpen && reel?.description && !isShopNow && !open && (
                <VStack
                  bottom={isShowProduct ? '17%' : '8%'}
                  left={'0%'}
                  w={'100%'}
                  mx={'auto'}
                  justifyContent={'flex-start'}
                  alignItems={'flex-start'}
                >
                  <Box
                    className="reelDesc"
                    ml={'5%'}
                    w={'84%'}
                    bg={'#0000001c'}
                    css={{
                      display: 'block',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: NUMBER_OF_LINES, // Number of lines to show
                      wordWrap: 'break-word'
                    }}
                    maxH={100}
                    overflow="auto"
                    p={2}
                    borderRadius={'lg'}
                    fontSize={'15px !important'}
                    boxShadow={'sm'}
                  >
                    {parse(reel.description ?? '')}
                  </Box>
                </VStack>
              )}
              {product && isShowProduct && (
                <VStack
                  bg={'white'}
                  px={2}
                  py={2}
                  w={WIDTH_CONTENT}
                  borderRadius={'lg'}
                  position={'relative'}
                >
                  <HStack w={'100%'} alignItems={'center'}>
                    <VStack flex={4}>
                      <ProductReelItem product={product} reel={reel} />
                    </VStack>
                    <VStack flex={1} alignItems={'end'}>
                      <IconButton
                        aria-label="close-reel-button"
                        icon={<SmallCloseIcon />}
                        bg={'transparent'}
                        _hover={{
                          bg: 'transparent'
                        }}
                        onClick={() => {
                          setIsShowProduct(false)
                        }}
                      ></IconButton>
                    </VStack>
                  </HStack>
                </VStack>
              )}
              <Box pl={7} zIndex={1000} w={'100%'}>
                <IconButton
                  aria-label="on-video-reel"
                  icon={<AddToCartIcon color={'orange'} />}
                  bg={'white'}
                  _hover={{
                    bg: 'white'
                  }}
                  onClick={() => {
                    setIsShopNow(true)
                  }}
                  borderRadius={'50%'}
                />
              </Box>
            </VStack>
          )}
          {products && isShopNow && isOpen && (
            <Fragment>
              <VStack
                position={'absolute'}
                alignItems={'start'}
                bottom={'0%'}
                w={'100%'}
                h={'auto'}
                zIndex={2000}
              >
                {isOpen && reel?.description && (
                  <VStack w={'100%'}>
                    <Box
                      className="reelDesc"
                      ml={'5%'}
                      w={'84%'}
                      bg={'#0000001c'}
                      css={{
                        display: 'block',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: NUMBER_OF_LINES, // Number of lines to show
                        wordWrap: 'break-word'
                      }}
                      maxH={100}
                      overflow="auto"
                      p={2}
                      borderRadius={'lg'}
                      fontSize={'15px !important'}
                      boxShadow={'sm'}
                    >
                      {parse(reel.description ?? '')}
                    </Box>
                  </VStack>
                )}
                <VStack
                  w={'100%'}
                  maxH={'320px'}
                  py={2}
                  overflowY={'scroll'}
                  bg={'white'}
                >
                  <HStack
                    w={'100%'}
                    justifyContent={'end'}
                    position={'absolute'}
                  >
                    <IconButton
                      aria-label="close-reel-button"
                      icon={<SmallCloseIcon />}
                      bg={'transparent'}
                      _hover={{
                        bg: 'transparent'
                      }}
                      onClick={() => {
                        setIsShopNow(false)
                      }}
                    ></IconButton>
                  </HStack>
                  {products.map((p) => {
                    return (
                      <ProductReelItem product={p} key={p.id} reel={reel} />
                    )
                  })}
                </VStack>
              </VStack>
            </Fragment>
          )}
          {open && (
            <VStack
              position={'absolute'}
              top={'85%'}
              left={0}
              w={'100%'}
              h={150}
              zIndex={2000}
              bg={'white'}
            >
              <HStack w={'100%'} justifyContent={'end'}>
                <IconButton
                  aria-label="close-reel-button"
                  icon={<SmallCloseIcon />}
                  bg={'transparent'}
                  _hover={{
                    bg: 'transparent'
                  }}
                  onClick={closeHandle}
                ></IconButton>
              </HStack>
              <HStack px={5} spacing={8} w={'100%'} justifyContent={'center'}>
                <VStack alignItems={'center'}>
                  <Tooltip label={valueCopy != null ? 'copied' : 'copy link'}>
                    <IconButton
                      aria-label="copy-link-reel"
                      icon={<CopyLinkIcon />}
                      borderRadius={'50%'}
                      bg={'red'}
                      _hover={{
                        bg: 'red'
                      }}
                      onClick={() => {
                        copy(SHARE_LINK)
                        addShare(reel, () => {
                          setShareNumber(shareNumber + 1)
                        })
                      }}
                    ></IconButton>
                  </Tooltip>
                  <Text textStyle={'iconReel'}>Copy link</Text>
                </VStack>
                <VStack alignItems={'center'}>
                  <FacebookShareButton
                    url={reel?.social_share?.facebook ?? SHARE_LINK}
                    onClick={() => {
                      addShare(reel, () => {
                        setShareNumber(shareNumber + 1)
                      })
                    }}
                  >
                    <FacebookIcon size={ICON_SIZE} borderRadius={50} />
                  </FacebookShareButton>
                  <Text textStyle={'iconReel'}>Facebook</Text>
                </VStack>
                <VStack alignItems={'center'}>
                  <WhatsappShareButton
                    url={reel?.social_share?.whatsapp ?? SHARE_LINK}
                    onClick={() => {
                      addShare(reel, () => {
                        setShareNumber(shareNumber + 1)
                      })
                    }}
                  >
                    <WhatsappIcon size={ICON_SIZE} borderRadius={50} />
                  </WhatsappShareButton>
                  <Text textStyle={'iconReel'}>Whatsapp</Text>
                </VStack>
                <VStack alignItems={'center'}>
                  <TelegramShareButton
                    url={reel?.social_share?.telegram ?? SHARE_LINK}
                    onClick={() => {
                      addShare(reel, () => {
                        setShareNumber(shareNumber + 1)
                      })
                    }}
                  >
                    <TelegramIcon size={ICON_SIZE} borderRadius={50} />
                  </TelegramShareButton>
                  <Text textStyle={'iconReel'}>Telegram</Text>
                </VStack>
              </HStack>
            </VStack>
          )}
        </Box>
      </Box>
    </Flex>
  )
}

export default ReelItem
