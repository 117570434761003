import React from 'react'
import { IconPropsType } from './icon-props'

const CopyLinkIcon: React.FC<IconPropsType> = ({ color }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="16.000000pt"
      height="16.000000pt"
      viewBox="0 0 16.000000 16.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,16.000000) scale(0.100000,-0.100000)"
        fill={color ?? 'white'}
        stroke="none"
      >
        <path
          d="M80 131 c0 -6 9 -11 20 -11 11 0 20 -6 20 -14 0 -20 -25 -37 -44 -30
-9 3 -16 1 -16 -5 0 -20 29 -24 56 -6 36 23 31 69 -8 73 -16 2 -28 -1 -28 -7z"
        />
        <path
          d="M42 94 c-34 -23 -27 -68 11 -72 15 -2 27 1 27 7 0 6 -9 11 -20 11
-11 0 -20 6 -20 14 0 21 25 37 45 30 13 -5 16 -3 13 7 -7 20 -30 22 -56 3z"
        />
      </g>
    </svg>
  )
}

export default CopyLinkIcon
