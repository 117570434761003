import { IconProps } from '@chakra-ui/react'
import React from 'react'

const VolumeIcon: React.FC<IconProps> = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="16.000000pt"
      height="16.000000pt"
      viewBox="0 0 16.000000 16.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,16.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M57 130 c-14 -11 -32 -20 -41 -20 -11 0 -16 -9 -16 -30 0 -21 5 -30
16 -30 9 0 27 -9 41 -20 14 -11 30 -20 34 -20 5 0 9 32 9 70 0 39 -4 70 -9 70
-4 0 -20 -9 -34 -20z"
        />
        <path
          d="M136 115 c4 -10 3 -16 -2 -13 -5 4 -9 -7 -9 -22 0 -15 4 -26 9 -22 5
3 6 -3 2 -13 -5 -15 -4 -16 9 -5 19 16 19 64 0 80 -13 11 -14 10 -9 -5z m11
-47 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z"
        />
      </g>
    </svg>
  )
}

export default VolumeIcon
